import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { submenuData } from "../../data/leftMenu";
import LeftSubmenu, { MenuItem, MenuItems, checkActive } from "./LeftSubmenu";
import envVarConfig from "../../env/envVarConfig";
const StyledLeftMenu = styled.div.withConfig({ displayName: "StyledLeftMenu", componentId: "-1blyi8s" }) `
  width: 12.5%;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  border-radius: 20px 0px 0px 20px;
  box-shadow: 3px 1.600000023841858px 8px 0px rgba(0, 0, 0, 0.08),
    0px 0.30000001192092896px 0.8999999761581421px 0px rgba(0, 0, 0, 0.1);
  z-index: 2;
`;
const MenuHeaderFooter = styled.div.withConfig({ displayName: "MenuHeaderFooter", componentId: "-1mk93zg" }) `
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 2rem;
`;
const MenuFooter = styled(MenuHeaderFooter).withConfig({ displayName: "MenuFooter", componentId: "-kkz9ny" }) `
  gap: 0;
`;
const MenuHeaderTextContainer = styled.div.withConfig({ displayName: "MenuHeaderTextContainer", componentId: "-14bri2l" }) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`;
const MenuHeaderTextTitle = styled.span.withConfig({ displayName: "MenuHeaderTextTitle", componentId: "-qki2zq" }) `
  color: #0f0e0e;
  font-size: 1.3em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const MenuHeaderTextVersion = styled.span.withConfig({ displayName: "MenuHeaderTextVersion", componentId: "-a289pl" }) `
  color: #707070;
  font-size: 0.8em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const MenuFooterItem = styled(MenuItem).withConfig({ displayName: "MenuFooterItem", componentId: "-1scdglc" }) `
  color: #512da8;
  padding-left: 0;
  font-weight: 400;
  font-size: 0.85em;
  justify-content: center;
  &:first-of-type {
    border-bottom: 1px solid #ccc;
  }
`;
const LeftMenu = ({ onSubmenuVisibilityChange }) => {
    const location = useLocation();
    const [submenu, setSubmenu] = useState();
    const handleMenuItemClick = (menuItemKey) => {
        if (submenuData[menuItemKey] && submenu !== menuItemKey) {
            setSubmenu(menuItemKey);
            onSubmenuVisibilityChange(true);
        }
        else {
            setSubmenu(undefined);
            onSubmenuVisibilityChange(false);
        }
    };
    const [secondLevelVisible, setSecondLevelVisible] = React.useState(false);
    const [secondSettingsLevelVisible, setSecondSettingsLevelVisible] = React.useState(false);
    const nonProdEnv = envVarConfig.environment === "local" ||
        envVarConfig.environment === "dev" ||
        envVarConfig.environment === "test";
    return (_jsxs(_Fragment, { children: [_jsxs(StyledLeftMenu, { children: [_jsxs(MenuHeaderFooter, { children: [_jsx("img", { src: "/lvt_logo.svg", alt: "logo" }), _jsxs(MenuHeaderTextContainer, { children: [_jsx(MenuHeaderTextTitle, { children: "Operations Center" }), _jsx(MenuHeaderTextVersion, { children: "Version 1.1.0" })] })] }), _jsxs(MenuItems, { children: [_jsx(MenuItem, Object.assign({ onClick: handleMenuItemClick.bind(null, ""), to: "/", className: !submenu && checkActive(location, "/") }, { children: "Home" })), _jsx(MenuItem, Object.assign({ onClick: handleMenuItemClick.bind(null, "analytics"), to: location.pathname, className: submenu === "analytics" ||
                                    (submenu === undefined && checkActive(location, "/analytics"))
                                    ? "active"
                                    : "" }, { children: "Analytics" })), _jsx(MenuItem, Object.assign({ onClick: handleMenuItemClick.bind(null, "directory"), className: checkActive(location, "/directory"), to: "/directory" }, { children: "Directory" })), _jsx(MenuItem, Object.assign({ onClick: handleMenuItemClick.bind(null, "intranet"), to: location.pathname, className: submenu === "intranet" ||
                                    (submenu === undefined && checkActive(location, "/intranet"))
                                    ? "active"
                                    : "" }, { children: "Intranet" })), _jsx(MenuItem, Object.assign({ onClick: handleMenuItemClick.bind(null, "everywhere"), to: location.pathname, className: submenu === "everywhere" ||
                                    (submenu === undefined && checkActive(location, "/everywhere"))
                                    ? "active"
                                    : "" }, { children: "Everywhere" })), _jsx(MenuItem, Object.assign({ to: "/ixp", className: submenu === "ixp" ||
                                    (submenu === undefined && checkActive(location, "/ixp"))
                                    ? "active"
                                    : "", onClick: handleMenuItemClick.bind(null, "ixp") }, { children: "IXP" })), _jsx(MenuItem, Object.assign({ onClick: handleMenuItemClick.bind(null, "reach"), to: "/reach", className: checkActive(location, "/reach") }, { children: "Reach" }))] }), _jsxs(MenuFooter, { children: [_jsx(MenuFooterItem, Object.assign({ onClick: handleMenuItemClick.bind(null, "settings"), to: location.pathname, className: submenu === "settings" ||
                                    (submenu === undefined && checkActive(location, "/settings"))
                                    ? "active"
                                    : "" }, { children: "Settings" })), _jsx(MenuFooterItem, Object.assign({ to: "/login" }, { children: "Logout" }))] })] }), _jsx(LeftSubmenu, { items: submenuData[submenu], onClose: () => handleMenuItemClick(location.pathname) })] }));
};
export default LeftMenu;
