import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import styled from "styled-components";
import { useBranding } from "./BrandingContext";
import NavigationComponent from "./NavigationComponent";
import Edit from "../../../../public/Edit.svg";
import BrandingHeader from "./components/brandingHeader";
import { Button, Combobox, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, useComboboxFilter } from "@fluentui/react-components";
import { Dismiss12Regular } from "@fluentui/react-icons";
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-16b0bg1" }) `
  min-height: calc(100vh - 200px);
  padding-left: 64px;
  padding-top: 160px;
  display: flex;
  flex-direction: column;
`;
const EditLink = styled.span.withConfig({ displayName: "EditLink", componentId: "-hnbh57" }) `
  font-size: 14px;
  font-weight: 400;
  color: #512da8;
  cursor: pointer;
`;
const Icon = styled.img.withConfig({ displayName: "Icon", componentId: "-otfeiv" }) `
  margin: 0 0 0 5px;
`;
const CustomCombobox = styled(Combobox).withConfig({ displayName: "CustomCombobox", componentId: "-1bxly3h" }) `
  padding-left: 22px;

  .mode-wizard & {
    max-width: 400px;
  }

  &:before {
    content: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M14 2.75C9.99594 2.75 6.75 5.99594 6.75 10C6.75 11.7319 7.35725 13.3219 8.37045 14.5688L3.46967 19.4697C3.17678 19.7626 3.17678 20.2374 3.46967 20.5303C3.73594 20.7966 4.1526 20.8208 4.44621 20.6029L4.53033 20.5303L9.43118 15.6295C10.6781 16.6427 12.2681 17.25 14 17.25C18.0041 17.25 21.25 14.0041 21.25 10C21.25 5.99594 18.0041 2.75 14 2.75ZM14 4.25C17.1756 4.25 19.75 6.82436 19.75 10C19.75 13.1756 17.1756 15.75 14 15.75C10.8244 15.75 8.25 13.1756 8.25 10C8.25 6.82436 10.8244 4.25 14 4.25Z' fill='%23707070'/%3e %3c/svg%3e");
    position: absolute;
    left: 10px;
    top: 8px;
  }
`;
const CustomComboboxDropdown = styled.div.withConfig({ displayName: "CustomComboboxDropdown", componentId: "-1r8bs6g" }) `
  max-height: 360px;
  overflow-y: auto;
`;
const ComboboxUl = styled.ul.withConfig({ displayName: "ComboboxUl", componentId: "-qkefd4" }) `
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;

  .fui-DialogBody & {
    margin-block: 20px;
  }
`;
const CustomButton = styled(Button).withConfig({ displayName: "CustomButton", componentId: "-7brjgt" }) `
  && {
    min-width: 0;
    background-color: #F3F2F1;

    iframe & {
      max-width: 100%;
    }
  }
`;
const SiteCollection = (props) => {
    const { selectedTheme, setSelectedTheme } = useBranding();
    const { siteCollections } = useBranding();
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [query, setQuery] = React.useState("");
    const options = [];
    siteCollections === null || siteCollections === void 0 ? void 0 : siteCollections.forEach((site) => {
        options.push({ children: site.text, value: site.key });
    });
    const children = useComboboxFilter(query, options, {
        noOptionsMessage: "No site collections match your search.",
        optionToText: (option) => option.children,
    });
    const closeDialog = () => {
        setIsDialogVisible(false);
    };
    const getTextFromValue = (value) => {
        const site = siteCollections === null || siteCollections === void 0 ? void 0 : siteCollections.find((sc) => sc.key === value);
        return site === null || site === void 0 ? void 0 : site.text;
    };
    const handleSelectionChange = (ev, option) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (ev) => setQuery(ev.target.value);
        if (selectedTheme) {
            const updatedTheme = Object.assign(Object.assign({}, selectedTheme), { siteCollectionUrls: option.selectedOptions });
            setSelectedTheme(updatedTheme);
        }
    };
    const onTagClick = (option, index) => {
        setSelectedTheme(Object.assign(Object.assign({}, selectedTheme), { siteCollectionUrls: selectedTheme.siteCollectionUrls.filter((o) => o !== option) }));
    };
    return (_jsx(_Fragment, { children: props.wizardMode ? (_jsxs(Container, Object.assign({ className: "mode-wizard" }, { children: [_jsx(BrandingHeader, { title: "Select the site collections", summary: "Define the site collection(s) to which your theme will be applied to" }), _jsx(CustomCombobox, Object.assign({ placeholder: "Type the site collection name", onChange: (ev) => setQuery(ev.target.value), value: query, multiselect: true, freeform: true, clearable: true, size: "large", selectedOptions: selectedTheme.siteCollectionUrls, onOptionSelect: handleSelectionChange, positioning: "below" }, { children: _jsx(CustomComboboxDropdown, { children: children }) })), selectedTheme.siteCollectionUrls.length ? (_jsx(ComboboxUl, { children: selectedTheme.siteCollectionUrls.map((option, i) => (_jsx("li", { children: _jsx(CustomButton, Object.assign({ shape: "circular", appearance: "secondary", icon: _jsx(Dismiss12Regular, {}), iconPosition: "after", onClick: () => onTagClick(option, i) }, { children: getTextFromValue(option) })) }, option))) })) : null, _jsx(NavigationComponent, { currentStepName: "Site Collections" })] }))) : (_jsx("div", Object.assign({ className: "mode-edit" }, { children: _jsxs(ComboboxUl, { children: [selectedTheme.siteCollectionUrls &&
                        selectedTheme.siteCollectionUrls.length > 0
                        ? selectedTheme.siteCollectionUrls.slice(0, 3).map((siteKey) => {
                            const site = siteCollections === null || siteCollections === void 0 ? void 0 : siteCollections.find((sc) => sc.key === siteKey);
                            return (_jsx("li", { children: _jsx(CustomButton, Object.assign({ size: "small", shape: "circular", appearance: "secondary" }, { children: site === null || site === void 0 ? void 0 : site.text })) }, siteKey));
                        })
                        : null, selectedTheme.siteCollectionUrls.length > 3 && (_jsxs(CustomButton, Object.assign({ size: "small", shape: "circular", appearance: "secondary" }, { children: ["+", selectedTheme.siteCollectionUrls.length - 3] }))), selectedTheme.siteCollectionUrls.length === 0 && (_jsx(CustomButton, Object.assign({ size: "small", shape: "circular", appearance: "secondary" }, { children: "Tenant" }))), _jsx(Icon, { src: Edit, alt: "Edit" }), _jsxs(Dialog, Object.assign({ open: isDialogVisible, onOpenChange: (event, data) => setIsDialogVisible(data.open) }, { children: [_jsx(DialogTrigger, Object.assign({ disableButtonEnhancement: true }, { children: _jsx(EditLink, { children: "Edit site collection" }) })), _jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(DialogTitle, { children: "Edit Site Collections" }), _jsxs(DialogContent, { children: [_jsx("p", { children: "Select or remove site collections." }), _jsx(Field, { children: _jsx(CustomCombobox, Object.assign({ placeholder: "Type the site collection name", onChange: (ev) => setQuery(ev.target.value), value: query, multiselect: true, freeform: true, size: "large", selectedOptions: selectedTheme.siteCollectionUrls, onOptionSelect: handleSelectionChange, positioning: "below" }, { children: _jsx(CustomComboboxDropdown, { children: children }) })) }), selectedTheme.siteCollectionUrls.length ? (_jsx(ComboboxUl, { children: selectedTheme.siteCollectionUrls.map((option, i) => (_jsx("li", { children: _jsx(CustomButton, Object.assign({ size: "small", shape: "circular", appearance: "secondary", icon: _jsx(Dismiss12Regular, {}), iconPosition: "after", onClick: () => onTagClick(option, i) }, { children: getTextFromValue(option) })) }, option))) })) : null] }), _jsx(DialogActions, { children: _jsx(Button, Object.assign({ onClick: closeDialog }, { children: "Close" })) })] }) })] }))] }) }))) }));
};
export default SiteCollection;
