import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { EditingModal } from "../Modals/EditingModal/EditingModal";
import { EditingSecondLevelNode } from "./EditingSecondLevelNode";
import { DroppableContainer, levelNoticeStyle, ListWrapper, } from "../ContentEditor.styles";
import { StrictModeDroppable } from "../StrictModeDroppable";
import { Button } from "@fluentui/react-components";
const LevelWrapper = styled.div.withConfig({ displayName: "LevelWrapper", componentId: "-j9lt9b" }) ``;
const AddNewNodeWrapper = styled.div.withConfig({ displayName: "AddNewNodeWrapper", componentId: "-1dtnsfh" }) `
  display: flex;
  justify-content: right;
`;
const LevelNotice = styled.div.withConfig({ displayName: "LevelNotice", componentId: "-rz4fz2" }) `
  ${levelNoticeStyle}
`;
const AddButton = styled(Button).withConfig({ displayName: "AddButton", componentId: "-7omwbb" }) `
  && {
    min-width: 0;
    margin-top: .5rem;
  }
`;
export const EditingSecondLevel = (props) => {
    var _a, _b;
    const [secondLevelNodes, setSecondLevelNodes] = React.useState(((_a = props.node) === null || _a === void 0 ? void 0 : _a.nodes) ? (_b = props.node) === null || _b === void 0 ? void 0 : _b.nodes : []);
    const [showModal, setShowModal] = React.useState(false);
    function addNewNode() {
        setShowModal(true);
    }
    function createNode(node) {
        const nodes = secondLevelNodes;
        nodes === null || nodes === void 0 ? void 0 : nodes.push(node);
        setSecondLevelNodes(nodes);
        props.setSelectedSecondLevelNode(node);
        props.updateSecondLevelNodes(props.node, nodes);
        setShowModal(false);
    }
    function closeModal() {
        setShowModal(false);
    }
    function deleteNode(node) {
        const nodes = secondLevelNodes;
        nodes.splice(nodes.indexOf(node), 1);
        setSecondLevelNodes(nodes);
        props.updateSecondLevelNodes(props.node, nodes);
    }
    function updateNode(newNode, oldNode) {
        const nodes = secondLevelNodes;
        nodes[secondLevelNodes.indexOf(oldNode)] = newNode;
        props.updateSecondLevelNodes(props.node, nodes);
        props.setSelectedNode(newNode);
    }
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const newNodes = reorder(secondLevelNodes, result.source.index, result.destination.index);
        setSecondLevelNodes(newNodes);
        props.updateSecondLevelNodes(props.node, newNodes);
    }
    const getItemStyle = (isDragging, draggableStyle) => (Object.assign({ userSelect: "none", background: isDragging ? "inhreit" : "none" }, draggableStyle));
    return (_jsxs(LevelWrapper, Object.assign({ className: props.isActive ? "activeLevel" : "" }, { children: [_jsx("h3", { children: "2nd level" }), _jsx(DragDropContext, Object.assign({ onDragEnd: onDragEnd }, { children: _jsx(DroppableContainer, { children: _jsx(StrictModeDroppable, Object.assign({ droppableId: "droppable_levl1", direction: "vertical" }, { children: (provided) => (_jsxs(ListWrapper, Object.assign({ ref: provided.innerRef }, provided.droppableProps, { children: [props.noParentNodeSelected && (_jsx(LevelNotice, { children: "Select a first level node to enable next levels" })), secondLevelNodes.length === 0 &&
                                    props.node &&
                                    props.isActive && (_jsx(LevelNotice, { children: "No items added yet" })), secondLevelNodes.map((n, i) => {
                                    var _a, _b;
                                    return (_jsx(Draggable, Object.assign({ draggableId: ((_a = n.labels[0]) === null || _a === void 0 ? void 0 : _a.value) + i, index: i }, { children: (provided, snapshot) => {
                                            var _a;
                                            return (_jsx("div", Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { style: getItemStyle(snapshot.isDragging, provided.draggableProps.style) }, { children: _jsx(EditingSecondLevelNode, { isInPath: props.selectedSecondLevelNode !==
                                                        props.selectedNode &&
                                                        props.selectedSecondLevelNode !== null &&
                                                        props.selectedSecondLevelNode === n, selectedLanguage: props.selectedLanguage, selectedSecondLevelNode: props.selectedSecondLevelNode, isActive: props.selectedSecondLevelNode !== null &&
                                                        props.selectedSecondLevelNode !== undefined &&
                                                        ((_a = props.selectedSecondLevelNode) === null || _a === void 0 ? void 0 : _a.key) === n.key, setSelectedSecondLevelNode: (node) => {
                                                        props.setSelectedSecondLevelNode(node);
                                                    }, updateNode: updateNode, deleteNode: deleteNode, node: n }, crypto.randomUUID()) })));
                                        } }), ((_b = n.labels[0]) === null || _b === void 0 ? void 0 : _b.value) + i));
                                }), provided.placeholder] }))) })) }) })), _jsx(AddNewNodeWrapper, { children: _jsx(AddButton, Object.assign({ onClick: addNewNode, disabled: props.isActive ? false : true }, { children: "Add" })) }), _jsx(EditingModal, { selectedLanguage: props.selectedLanguage, closeModal: closeModal, updateNode: createNode, showModal: showModal })] })));
};
